/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --header-height: 84px;
}

html,
body {
  @apply text-primary;
  height: 100%;
}

@layer utilities {
  .outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
}
